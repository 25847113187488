<nav class="navbar navbar-expand-lg navbar-light">
  <div class="navbar-brand" [setSVGAttributes]='{"width": "100%"}' [inlineSVG]="'/assets/images/logo-tp.svg'"></div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <li class="nav-item">
        <figure class="nav-link" routerLink="/dashboard" routerLinkActive="active">
          <div class="image" [inlineSVG]="'/assets/icons/dashboard-icon.svg'"></div>
          <figcaption i18n="generic-dashboard|Intestazione per la Dashboard">Dashboard</figcaption>
        </figure>
      </li>
      <li class="nav-item" *ngIf="auth.canDoIt('addclub')">
        <figure class="nav-link" routerLink="/clubs" routerLinkActive="active">
          <div class="image" [inlineSVG]="'/assets/images/default-shield.svg'"></div>
          <figcaption i18n="generic-sessions|Gestione club">Club</figcaption>
        </figure>
      </li>
      <li *ngIf="auth.canDoIt('addtracker')" class="nav-item">
        <figure class="nav-link" routerLink="/trackers" routerLinkActive="active">
          <div class="image" [inlineSVG]="'/assets/icons/wearable_tracker.svg'"></div>
          <figcaption i18n="generic-sessions|Gestione dispositivi">Dispositivi</figcaption>
        </figure>
      </li>
      <li class="nav-item">
        <figure class="nav-link" routerLink="/sessions" routerLinkActive="active">
          <div class="image" [inlineSVG]="'/assets/icons/sessions-icon.svg'"></div>
          <figcaption i18n="generic-sessions|Intestazione per le Sessioni">Sessioni</figcaption>
        </figure>
      </li>
      <li class="nav-item">
        <figure class="nav-link" routerLink="/teams" routerLinkActive="active">
          <div class="image" [inlineSVG]="'/assets/icons/teams-icon.svg'"></div>
          <figcaption i18n="generic-teams|Intestazione per le Squadre">Squadre</figcaption>
        </figure>
      </li>
      <li class="nav-item">
        <figure class="nav-link" routerLink="/players" routerLinkActive="active">
          <div class="image" [inlineSVG]="'/assets/icons/players-icon.svg'"></div>
          <figcaption i18n="generic-players|Intestazione per i Giocatori">Giocatori</figcaption>
        </figure>
      </li>
      <li *ngIf="!isProduction" class="nav-item">
        <figure class="nav-link" routerLink="/settings" routerLinkActive="active">
          <div class="image" [inlineSVG]="'/assets/icons/setting-icon.svg'"></div>
          <figcaption i18n="generic-settings|Intestazione per le Impostazioni">Setting</figcaption>
        </figure>
      </li>
    </ul>
  </div>
  <ul class="navbar-nav">
    <li class="nav-item">
      <figure class="nav-link" (click)="logout()">
        <div class="image" [inlineSVG]="'/assets/icons/logout-icon.svg'"></div>
        <figcaption i18n="generic-logout|Intestazione per il Logout">Logout</figcaption>
      </figure>
    </li>
  </ul>
</nav>
